import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/vercel/path0/node_modules/next-themes/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/assets/fonts/gallerymodern-webfont.ttf\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../public/assets/fonts/gallerymodern-webfont.woff\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../public/assets/fonts/gallerymodern-webfont.woff2\",\"weight\":\"400\",\"style\":\"normal\"}],\"variable\":\"--tp-ff-gallery\"}],\"variableName\":\"gellery\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Aladin\",\"arguments\":[{\"weight\":[\"400\"],\"subsets\":[\"latin\"],\"variable\":\"--tp-ff-aladin\"}],\"variableName\":\"aladin\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Syne\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"600\",\"700\",\"800\"],\"subsets\":[\"latin\"],\"variable\":\"--tp-ff-body\"}],\"variableName\":\"syne_body\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Syne\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"600\",\"700\",\"800\"],\"subsets\":[\"latin\"],\"variable\":\"--tp-ff-heading\"}],\"variableName\":\"syne_heading\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Syne\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"600\",\"700\",\"800\"],\"subsets\":[\"latin\"],\"variable\":\"--tp-ff-p\"}],\"variableName\":\"syne_p\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Syne\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"600\",\"700\",\"800\"],\"subsets\":[\"latin\"],\"variable\":\"--tp-ff-syne\"}],\"variableName\":\"syne\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Big_Shoulders_Display\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"600\",\"700\",\"800\"],\"subsets\":[\"latin\"],\"variable\":\"--tp-ff-shoulders\"}],\"variableName\":\"big_shoulders\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Marcellus\",\"arguments\":[{\"weight\":[\"400\"],\"subsets\":[\"latin\"],\"variable\":\"--tp-ff-marcellus\"}],\"variableName\":\"marcellus\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.scss");
